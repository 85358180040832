#page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  z-index: 50;
  transition: 1s all;

  &.visibility-background {
    background-color: rgba(0, 0, 0, 0.4);
    padding: 8px 16px;
  }

  > div {
    display: flex;

    img {
      display: block;
      width: 48px;
      height: 48px;
      padding: 12px;
    }
  }

  #sound-btn {
    cursor: url("/src/assets/images/cursor-pointer.png"), auto;
  }
}
