#page-header {
  z-index: 50;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  transition: all 1s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

#page-header.visibility-background {
  background-color: #0006;
  padding: 8px 16px;
}

#page-header > div {
  display: flex;
}

#page-header > div img {
  width: 48px;
  height: 48px;
  padding: 12px;
  display: block;
}

#page-header #sound-btn {
  cursor: url("cursor-pointer.2fbfb758.png"), auto;
}

/*# sourceMappingURL=index.0e511009.css.map */
